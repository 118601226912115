import { APIUrlList } from '../js/api_url';
import { _sendResponse } from '../js/common-function';
import { apiCall, uploadToBucket } from '../js/common-function';

export const getCategory = async (id = null) => {
    try {
        let url = id ? APIUrlList.GETCATEGORYLIST + `?category_id=${id}` : APIUrlList.GETCATEGORYLIST;
        let catList = [];
        const response = await apiCall(url);
        if (response.data.status){
            if (id === null && response.data.data){
                response.data.data.map(value => {
                    let objToPush = {
                        id: value.id,
                        text: value.name,
                    };
                    catList.push(objToPush);
                });
            } else {
                if (response.data.data && response.data.data[0]){
                    if (response.data.data[0].sub_category){
                        response.data.data[0].sub_category.map(value => {
                            let objToPush = {
                                id: value.id,
                                text: value.sub_cat_name
                            };
                            catList.push(objToPush);
                        });
                    }
                }
            }
        } else {
            _sendResponse('error', response.data.message);
        }
        return catList;
    } catch (error) {
        _sendResponse('error', error);
    }
};


export const registerUser = async (userData, path) => {
    try {
        let uploaded = await uploadToBucket(userData.profile_picture, path);
        if (uploaded){
            userData.profile_pic = uploaded;
            let response = await apiCall(APIUrlList.REGISTRATION, userData, "POST", false);
            if (response.data.status){
                if (response.data.data && response.data.data.account_type != 2) {
                    _sendResponse('success', "Fan registered successfully.");
                }
            } else {
                _sendResponse('error', response.data.message);
            }
            return response.data;
        } else {
            return {status: false};
        }
    } catch (error){
        _sendResponse('error', error);
    }
};

export const getCountryList = async () => {
    try {
        let countries = [];
        let response = await apiCall(APIUrlList.GETCOUNTRYLIST);
        response.data.data.map(value =>{
            let objToPush ={
                id: value.id,
                text: value.name
            };
            countries.push(objToPush);
        });
        return countries;
    } catch (error) {
        _sendResponse('error', error);
    }
};

export const getStateList = async (countryId = "") => {
    try {
        let states = [];
        let response = await apiCall(APIUrlList.GETSTATELIST + `?country_id=${countryId}`);
        response.data.data.map(value =>{
            let objToPush ={
                id: value.id,
                text: value.name
            };
            states.push(objToPush);
        });
        return states;
    } catch (error) {
        _sendResponse('error', error);
    }
};

export const getCityList = async (countryId="", stateId="") => {
    try {
        let cities = [];
        let response = await apiCall(APIUrlList.GETCITYLIST + `?country_id=${countryId}&state_id=${stateId}`);
        response.data.data.map(value =>{
            let objToPush ={
                id: value.id,
                text: value.name
            };
            cities.push(objToPush);
        });
        return cities;
    } catch (error) {
        _sendResponse('error', error);
    }
};


export const updateUser = async (userData) => {
    try {
        let response =  await apiCall(APIUrlList.UPDATEPROFILE, userData, 'POST', true);
        if (response.data.status){
            _sendResponse('success', response.data.message);
        } else {
            _sendResponse('error', response.data.message);
        }
        return response.data;
    } catch (error) {
        _sendResponse('error', error);
    }
};

export const setCountryOptions = async () => {
    let countriesOptions = {
        countries:[],
        countriesnames:[],
        facountries:[],
        countriescode:{}
    };


    let objToPush = {
        id: '',
        text: 'Select Country',
    };
    countriesOptions.countries.push(objToPush);


    const response = await apiCall(APIUrlList.GETCOUNTRYLIST);
    if (response.data.status) {
        response.data.data.map(value => {
            let objToPush = {
                id: value.id,
                text: value.name,
            };
            countriesOptions.countries.push(objToPush);
            countriesOptions.countriesnames[value.name.toLowerCase().trim()] = value.id;
            countriesOptions.countriescode[value.id] = value.country_code;
        });
    }
    return countriesOptions;
};

export const setStateOptions = async (countryid) => {
    let stateOptions = {states:[], statenames:[]};
    let objToPush = {
        id: '',
        text: 'Select State',
    };
    stateOptions.states.push(objToPush);

    const response = await apiCall(APIUrlList.GETSTATELIST + `?country_id=${countryid}`);
    if (response.data.status) {
        response.data.data.map(value => {
            let objToPush = {
                id: value.id,
                text: value.name,
            };
            stateOptions.states.push(objToPush);
            stateOptions.statenames[value.name.toLowerCase().trim()] = value.id;
        });
    }
    return stateOptions;
};

export const setCityOptions = async (countryid,stateid) => {
    let cityOptions =  {cities:[], citynames:[]};
    let objToPush = {
        id: '',
        text: 'Select City',
    };
    cityOptions.cities.push(objToPush);

    const response = await apiCall(APIUrlList.GETCITYLIST + `?country_id=${countryid}&state_id=${stateid}`);
    if (response.data.status) {
        response.data.data.map(value => {
            let objToPush = {
                id: value.id,
                text: value.name,
            };
            cityOptions.cities.push(objToPush);
            cityOptions.citynames[value.name.toLowerCase().trim()] = value.id;
        });
    }
    return cityOptions;
};
